<template>
  <div class="md-layout">
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
    >
      <md-card>
        <md-card-header data-background-color="blue" class="card-header">
          <div class="card-header-info">
            <h4 class="title">Изменить товар</h4>
          </div>
        </md-card-header>

        <ValidationObserver ref="editProductForm" :slim="true">
          <md-card-content v-if="isInited">
            <div class="md-layout-item md-large-size-100">
              <ValidationProvider
                v-slot="{ errors, reset }"
                mode="passive"
                :slim="true"
                
              >
                <md-field :class="{ error: errors.length }">
                  <ValidationError :errors="errors" />
                  <label>Название товара</label>
                  <md-input v-model="name" @focus="reset" @blur="rewriteProduct(1)"/>
                </md-field>
              </ValidationProvider>

              <ValidationProvider
                v-slot="{ errors, reset }"
                mode="passive"
                :slim="true"
                
              >
                <md-field :class="{ error: errors.length }">
                  <ValidationError :errors="errors" />
                  <label>Описание</label>
                  <md-textarea v-model="description" @focus="reset" @blur="rewriteProduct(1)"/>
                </md-field>
              </ValidationProvider>
            </div>

            <div class="md-layout-item md-large-size-100">
              <h5 class="md-subheading" style="margin-bottom: 15px">
                Добавить изображения
              </h5>
              <ValidationProvider
                ref="imageValidator"
                v-slot="{ errors }"
                mode="passive"
                :slim="true"
              >
                <div class="uploader-wrapper">
                  <ValidationError :errors="errors" />
                  <VueUploadMultipleImage
                    :key="`uploader_${uploaderKey}`"
                    drag-text="Drag here"
                    browse-text="Browse"
                    primary-text="Main image"
                    mark-is-primary-text="Set as main"
                    popup-text="This image will be set as main"
                    drop-text="Drop here"
                    accept="image/jpeg, image/png, image/jpg"
                    :max-image="5"
                    :show-edit="false"
                    :data-images="initImages"
                    @before-remove="beforeRemove"
                    @mark-is-primary="replaceImage"
                    @upload-success="imagesChange"
                    @blur="rewriteProduct(1)"
                  />
                </div>
              </ValidationProvider>
            </div>

            <div class="md-layout wrapper">
              <div class="md-layout-item md-large-size-50">
                <h5 class="md-subheading">Основные параметры товара</h5>

                <div class="row md-layout" style="margin: 20px -15px 0">
                  <div class="md-layout-item md-large-size-30 md-body-2">
                    Размер
                  </div>
                  <div class="md-layout-item md-large-size-30 md-body-2">
                    Цвет
                  </div>
                  <div class="md-layout-item md-large-size-20 md-body-2">
                    Кол-во
                  </div>
                  <div class="md-layout-item md-large-size-20"></div>
                </div>

                <div
                  v-for="(option, index) in options"
                  :key="'option_' + index"
                  class="row md-layout"
                  style="margin: 0 -15px"
                >
                  <ValidationProvider
                    v-slot="{ errors, reset }"
                    mode="passive"
                    
                    class="md-layout-item md-large-size-30"
                  >
                    <md-field :class="{ error: errors.length }">
                      <ValidationError :errors="errors" />
                      <md-input v-model="option.size" @focus="reset" @blur="rewriteProduct(1)"/>
                    </md-field>
                  </ValidationProvider>

                  <ValidationProvider
                    v-slot="{ errors, reset }"
                    mode="passive"
                    
                    class="md-layout-item md-large-size-30"
                  >
                    <md-field :class="{ error: errors.length }">
                      <ValidationError :errors="errors" />
                      <md-select v-model="option.color_id" @focus="reset" @blur="rewriteProduct(1)">
                        <md-option
                          v-for="item in colors"
                          :key="item.id"
                          :value="item.id"
                          placeholder="Цвет"
                        >
                          <div
                            :style="{
                              width: '20px',
                              height: '20px',
                              borderRadius: '50%',
                              marginRight: '10px',
                              flexShrink: '0',
                              backgroundColor: item.hex,
                            }"
                          ></div>
                          <span>{{ item.name }}</span>
                        </md-option>
                      </md-select>
                    </md-field>
                  </ValidationProvider>

                  <ValidationProvider
                    v-slot="{ errors, reset }"
                    mode="passive"
                    
                    class="md-layout-item md-large-size-20"
                  >
                    <md-field :class="{ error: errors.length }">
                      <ValidationError :errors="errors" />
                      <md-input
                        v-model="option.quantity"
                        type="number"
                        min="0"
                        @focus="reset"
                        @change="
                          onValueChange('quantity', option, $event.target.value)
                        "
                        @blur="rewriteProduct(1)"
                      />
                    </md-field>
                  </ValidationProvider>

                  <div
                    v-if="index !== 0"
                    class="btn-delete-wrap md-layout-item md-large-size-20"
                  >
                    <md-button
                      class="md-raised md-danger"
                      @click="deleteOption('option', index)"
                    >
                      <md-icon>delete</md-icon>
                    </md-button>
                  </div>
                </div>

                <div class="button-wrap">
                  <md-button
                    class="md-raised md-primary"
                    @click="addNewOption('option')"
                  >
                    Добавить параметр
                  </md-button>
                </div>

                <ValidationProvider
                  v-slot="{ errors, reset }"
                  mode="passive"
                  :slim="true"
                  
                >
                  <div class="treeselect-wrapper">
                    <ValidationError :errors="errors" />
                    <Treeselect
                      v-model="categoriesArray"
                      :multiple="true"
                      :options="categories"
                      :flat="true"
                      :auto-deselect-descendants="true"
                      :auto-select-descendants="true"
                      value-consists-of="LEAF_PRIORITY"
                      placeholder="Категории"
                      @open="reset"
                      @blur="rewriteProduct(1)"
                    />
                  </div>
                </ValidationProvider>

                <ValidationProvider
                  v-slot="{ errors, reset }"
                  mode="passive"
                  :slim="true"
                  
                >
                  <md-field :class="{ error: errors.length }">
                    <ValidationError :errors="errors" />
                    <label>Поставщик</label>
                    <md-select v-model="vendorId" @focus="reset" @blur="rewriteProduct(1)">
                      <md-option
                        v-for="item in vendors"
                        :key="item.id"
                        :value="item.id"
                        placeholder="Поставщик"
                      >
                        {{ item.name }}
                      </md-option>
                    </md-select>
                  </md-field>
                </ValidationProvider>

                <ValidationProvider
                  v-slot="{ errors, reset }"
                  mode="passive"
                  :slim="true"
                  
                >
                  <md-field :class="{ error: errors.length }">
                    <ValidationError :errors="errors" />
                    <label>Теги</label>
                    <md-select
                      v-model="tagsArray"
                      :multiple="true"
                      @focus="reset"
                      @blur="rewriteProduct(1)"
                    >
                      <md-option
                        v-for="item in tags"
                        :key="item.id"
                        :value="item.id"
                        placeholder="Теги"
                      >
                        {{ item.title }}
                      </md-option>
                    </md-select>
                  </md-field>
                </ValidationProvider>

                <h5 class="md-subheading">Стоимость товара</h5>

                <ValidationObserver ref="calculateCostForm" :slim="true">
                  <ValidationProvider
                    v-slot="{ errors, reset }"
                    mode="passive"
                    :slim="true"
                    
                  >
                    <md-field :class="{ error: errors.length }">
                      <ValidationError :errors="errors" />
                      <label>Цена закупки</label>
                      <md-input
                        v-model="purchasePrice"
                        type="number"
                        min="0"
                        @focus="reset"
                        @change="
                          onValueChange(
                            'purchasePrice',
                            null,
                            $event.target.value
                          )
                        "
                        @blur="rewriteProduct(1)"
                      />
                    </md-field>
                  </ValidationProvider>

                  <ValidationProvider
                    v-slot="{ errors, reset }"
                    mode="passive"
                    :slim="true"
                    
                  >
                    <md-field :class="{ error: errors.length }">
                      <ValidationError :errors="errors" />
                      <label>Валюта</label>
                      <md-select v-model="currency" @focus="reset" @blur="rewriteProduct(1)">
                        <md-option
                          v-for="item in currencies"
                          :key="item.id"
                          :value="item.value"
                          placeholder="Валюта"
                        >
                          {{ item.value }}
                        </md-option>
                      </md-select>
                    </md-field>
                  </ValidationProvider>

                  <ValidationProvider
                    v-slot="{ errors, reset }"
                    mode="passive"
                    :slim="true"
                    
                  >
                    <md-field :class="{ error: errors.length }">
                      <ValidationError :errors="errors" />
                      <label>Точный вес</label>
                      <md-input
                        v-model="weight"
                        type="number"
                        min="0"
                        @focus="reset"
                        @change="
                          onValueChange('weight', null, $event.target.value)
                        "
                        @blur="rewriteProduct(1)"
                      />
                    </md-field>
                  </ValidationProvider>

                  <ValidationProvider
                    v-slot="{ errors, reset }"
                    mode="passive"
                    :slim="true"
                    
                  >
                    <md-field :class="{ error: errors.length }">
                      <ValidationError :errors="errors" />
                      <label>Регион</label>
                      <md-select v-model="country" @focus="reset" @blur="rewriteProduct(1)">
                        <md-option
                          v-for="item in countries"
                          :key="item.id"
                          :value="item.name"
                          placeholder="Регион"
                        >
                          {{ item.name }}
                        </md-option>
                      </md-select>
                    </md-field>
                  </ValidationProvider>

                  <div class="row md-layout">
                    <md-field class="md-layout-item md-size-40">
                      <label>Рекомендуемая цена</label>
                      <md-input :value="priceForNewProduct" :disabled="true" />
                    </md-field>
                    <div
                      v-if="pickedCurrency && currency !== 'RUB'"
                      class="md-layout-item md-size-30"
                    >
                      {{ `1 ${pickedCurrency.value} = ${pickedCurrency.exchangeRate} RUB` }}
                    </div>
                    <div class="md-layout-item md-size-30">
                      <md-button
                        class="md-raised md-success"
                        @click="updateCurrency"
                      >
                        Обновить курсы валют
                      </md-button>
                      <md-button
                        class="md-raised md-primary"
                        @click="calculateCost"
                      >
                        Рассчитать
                      </md-button>
                    </div>
                  </div>
                </ValidationObserver>

                <ValidationProvider
                  v-slot="{ errors, reset }"
                  mode="passive"
                  :slim="true"
                  
                >
                  <md-field :class="{ error: errors.length }">
                    <ValidationError :errors="errors" />
                    <label>Цена</label>
                    <md-input
                      v-model="price"
                      type="number"
                      min="0"
                      @focus="reset"
                      @change="
                        onValueChange('price', null, $event.target.value)
                      "
                      @blur="rewriteProduct(1)"
                    />
                  </md-field>
                </ValidationProvider>

                <md-field>
                  <label>Старая цена</label>
                  <md-input
                    v-model="oldPrice"
                    type="number"
                    min="0"
                    @change="
                      onValueChange('oldPrice', null, $event.target.value)
                    "
                    @blur="rewriteProduct(1)"
                  />
                </md-field>
              </div>

              <div class="md-layout-item md-large-size-50">
                <h5 class="md-subheading">Характеристики товара</h5>

                <ValidationProvider
                  v-slot="{ errors, reset }"
                  mode="passive"
                  :slim="true"
                  
                >
                  <md-field :class="{ error: errors.length }">
                    <ValidationError :errors="errors" />
                    <label>Бренд</label>
                    <md-select v-model="brandId" @focus="reset" @blur="rewriteProduct(1)">
                      <md-option
                        v-for="item in brands"
                        :key="item.id"
                        :value="item.id"
                        placeholder="Бренд"
                      >
                        {{ item.name }}
                      </md-option>
                    </md-select>
                  </md-field>
                </ValidationProvider>

                <ValidationProvider
                  v-slot="{ errors, reset }"
                  mode="passive"
                  :slim="true"
                  
                >
                  <md-field :class="{ error: errors.length }">
                    <ValidationError :errors="errors" />
                    <label>Сезон</label>
                    <md-select
                      v-model="seasonsArray"
                      :multiple="true"
                      @focus="reset"
                      @blur="rewriteProduct(1)"
                    >
                      <md-option
                        v-for="item in seasons"
                        :key="item.id"
                        :value="item.id"
                        placeholder="Сезон"
                      >
                        {{ item.name }}
                      </md-option>
                    </md-select>
                  </md-field>
                </ValidationProvider>

                <div class="column options">
                  <div class="md-subheading">Материалы:</div>

                  <div
                    v-for="(material, index) in materials"
                    :key="`material_${index}`"
                    class="params md-layout"
                  >
                    <ValidationProvider
                      v-slot="{ errors, reset }"
                      mode="passive"
                      
                      class="md-layout-item md-large-size-40"
                    >
                      <md-field :class="{ error: errors.length }">
                        <ValidationError :errors="errors" />
                        <label>Материал</label>
                        <md-input v-model="material.name" @focus="reset" @blur="rewriteProduct(1)"/>
                      </md-field>
                    </ValidationProvider>

                    <ValidationProvider
                      v-slot="{ errors, reset }"
                      mode="passive"
                      
                      class="md-layout-item md-large-size-40"
                    >
                      <md-field :class="{ error: errors.length }">
                        <ValidationError :errors="errors" />
                        <label>Значение</label>
                        <md-input
                          v-model="material.value"
                          type="number"
                          min="0"
                          @focus="reset"
                          @change="
                            onValueChange(
                              'material',
                              material,
                              $event.target.value
                            )
                          "
                          @blur="rewriteProduct(1)"
                        />
                      </md-field>
                    </ValidationProvider>

                    <div
                      v-if="index !== 0"
                      class="btn-delete-wrap md-layout-item md-large-size-20"
                    >
                      <md-button
                        class="md-raised md-danger"
                        @click="deleteOption('material', index)"
                      >
                        <md-icon>delete</md-icon>
                      </md-button>
                    </div>
                  </div>

                  <div class="button-wrap">
                    <md-button
                      class="md-raised md-primary"
                      @click="addNewOption('material')"
                    >
                      Добавить материал
                    </md-button>
                  </div>
                </div>

                <h5 class="md-subheading">Дополнительные параметры</h5>
                <ValidationProvider
                  v-slot="{ errors, reset }"
                  mode="passive"
                  :slim="true"
                  
                >
                  <md-field :class="{ error: errors.length }">
                    <ValidationError :errors="errors" />
                    <label>Витрины</label>
                    <md-select
                      v-model="storefrontsArray"
                      :multiple="true"
                      @focus="reset"
                      @blur="rewriteProduct(1)"
                    >
                      <md-option
                        v-for="item in storefronts"
                        :key="item.id"
                        :value="item.id"
                        placeholder="Витрины"
                      >
                        {{ item.title }}
                      </md-option>
                    </md-select>
                  </md-field>
                </ValidationProvider>

                <ValidationProvider
                  v-slot="{ errors, reset }"
                  mode="passive"
                  :slim="true"
                  
                >
                  <md-datepicker
                    v-model="removalTime"
                    :md-model-type="String"
                    :class="{ error: errors.length }"
                    @md-opened="reset"
                    @blur="rewriteProduct(1)"
                  >
                    <ValidationError :errors="errors" />
                    <label>В архив</label>
                  </md-datepicker>
                </ValidationProvider>

                <md-checkbox v-model="isNotActive">
                  Снять с публикации
                </md-checkbox>
              </div>
            </div>

            <div class="buttons-wrap">
              <md-button class="md-raised md-success" @click="rewriteProduct">
                Сохранить
              </md-button>

              <md-button
                class="md-raised md-danger"
                @click="showModalConfirm = true"
              >
                Удалить
              </md-button>
            </div>
          </md-card-content>
        </ValidationObserver>
      </md-card>
    </div>

    <ModalConfirm
      v-if="showModalConfirm"
      text="Вы точно хотите удалить данный товар?"
      @confirm="deleteProduct"
      @close="showModalConfirm = false"
    />
  </div>
</template>

<script>
import ModalConfirm from "@/components/Modals/Confirm";

import { mapState, mapGetters, mapActions } from "vuex";

import VueUploadMultipleImage from "vue-upload-multiple-image";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import { formatISODate } from "@/helpers/formatDate";

export default {
  components: {
    ModalConfirm,
    Treeselect,
    VueUploadMultipleImage,
  },

  data() {
    return {
      uploaderKey: 0,
      isInited: false,
      initImages: [],
      name: null,
      description: null,
      images: [],
      options: [],
      categoriesArray: [],
      currency: null,
      purchasePrice: null,
      price: null,
      oldPrice: null,
      vendorId: null,
      tagsArray: [],
      brandId: null,
      seasonsArray: [],
      materials: [],
      storefrontsArray: [],
      removalTime: null,
      country: null,
      weight: null,
      isNotActive: false,
      showModalConfirm: false,
    };
  },

  computed: {
    ...mapState({
      brands: (state) => state.additionalData.brands,
      colors: (state) => state.additionalData.colors,
      countries: (state) => state.additionalData.countries,
      currencies: (state) => state.additionalData.currencies,
      priceForNewProduct: (state) => state.products.priceForNewProduct,
      product: (state) => state.products.product,
      seasons: (state) => state.additionalData.seasons,
      storefronts: (state) => state.additionalData.storefronts,
      tags: (state) => state.additionalData.tags,
      vendors: (state) => state.additionalData.vendors,
    }),

    ...mapGetters({ categories: "additionalData/reworkedCategories" }),

    pickedCurrency() {
      return this.currencies?.find(el => el.value === this.currency);
    }
  },

  watch: {
    priceForNewProduct(value) {
      if (value) {
        this.price = value;
      }
    },
  },

  async mounted() {
    this.$store.commit("SET_SHOW_LOADER", true);

    await this.getAdditionalData();
    let success = await this.getProduct(+this.$route.params.id);
    if (success) {
      this.name = this.product.name;
      this.description = this.product.description;

      this.product?.media?.length &&
        this.product.media.forEach(async (image, index) => {
          this.initImages.push({
            path: image.original_url,
            default: !!(index == 0),
            highlight: 1,
          });
          let b64 = await this.getProductImage(image.original_url);
          const buff = Buffer.from(b64, "base64");
          let name = "tmp" + +new Date();

          this.images.push(new File([buff], name, { type: "image/png" }));
        });
      this.uploaderKey++;

      this.options =
        this.product?.product_options?.length &&
        this.product?.product_options?.slice();

      this.categoriesArray =
        this.product?.categories?.length &&
        this.product.categories.map((item) => item.category_id);

      this.purchasePrice = this.product.purchase_price;
      this.currency = this.product.purchase_price_currency;
      this.price = this.product.price;
      this.oldPrice = this.product.old_price ?? null;
      this.vendorId = this.product.vendor_id;

      this.tagsArray =
        this.product?.tags?.length && this.product.tags.map((tag) => tag.id);

      this.brandId = this.product.brand_id;

      this.seasonsArray =
        this.product?.product_seasons?.length &&
        this.product.product_seasons.map((item) => item.season_id);

      this.materials =
        this.product?.features?.length && this.product?.features?.slice();

      this.storefrontsArray =
        this.product?.storefronts?.length &&
        this.product.storefronts.map((item) => item.storefront_id);

      this.removalTime = this.product?.removal_time?.slice(
        0,
        this.product.removal_time.indexOf("T")
      );
      this.country = this.product.region;
      this.weight = this.product.weight;
      this.isNotActive = !this.product.status;

      this.isInited = true;
    }

    this.$store.commit("SET_SHOW_LOADER", false);
  },

  methods: {
    ...mapActions("additionalData", ["getAdditionalData"]),
    ...mapActions("products", [
      "getPrice",
      "getProduct",
      "getProductImage",
      "removeProduct",
      "updateProduct",
      'currencyUpdate',
    ]),

    addNewOption(type) {
      switch (type) {
        case "option":
          this.options.push({
            size: "",
            color_id: null,
            quantity: "",
          });
          break;
        case "material":
          this.materials.push({ name: "", value: "" });
          break;
        default:
          break;
      }
    },

    beforeRemove(index, done) {
      this.images.splice(index, 1);
      done();
    },

    async calculateCost() {
      const isValid = await this.$refs.calculateCostForm.validate();
      if (!isValid) {
        return;
      }

      let fd = new FormData();
      fd.append("price", this.purchasePrice);
      fd.append("currency", this.currency);
      fd.append("weight", this.weight);
      fd.append("country", this.country);

      this.$store.commit("SET_SHOW_LOADER", true);
      await this.getPrice(fd);
      this.$store.commit("SET_SHOW_LOADER", false);
    },

    async updateCurrency() {
      this.$store.commit("SET_SHOW_LOADER", true);
      await this.currencyUpdate();
      this.$store.commit("SET_SHOW_LOADER", false);
    },

    deleteOption(type, index) {
      switch (type) {
        case "option":
          this.options.splice(index, 1);
          break;
        case "material":
          this.materials.splice(index, 1);
          break;
        default:
          break;
      }
    },

    async deleteProduct() {
      this.$store.commit("SET_SHOW_LOADER", true);
      let success = await this.removeProduct(+this.$route.params.id);
      if (success) {
        this.$router.replace("/products");
      }
      this.$store.commit("SET_SHOW_LOADER", false);
    },

    getDate(isoDate) {
      return formatISODate(isoDate);
    },

    imagesChange(formData) {
      this.$refs.imageValidator.reset();
      this.images.push(formData.get("file"));
    },

    onValueChange(fieldName, item, value) {
      if (value < 0) {
        switch (fieldName) {
          case "quantity":
            item.quantity = 0;
            break;
          case "purchasePrice":
            this.purchasePrice = 0;
            break;
          case "weight":
            this.weight = 0;
            break;
          case "price":
            this.price = 0;
            break;
          case "oldPrice":
            this.oldPrice = 0;
            break;
          case "material":
            item.value = 0;
            break;
          default:
            break;
        }
      }
    },

    replaceImage(index) {
      this.images = this.images.splice(index, 1).concat(this.images);
    },

    async rewriteProduct(draft = 0) {
      const isValid = await this.$refs.editProductForm.validate();
      if (!isValid) {
        return;
      }
      // console.log(draft);
      // return;

      // if (!this.images.length) {
      //   this.$refs.imageValidator.setErrors([
      //     "Необходимо добавить хотя бы одно изображение",
      //   ]);
      //   return;
      // }

      let product = new FormData();
      product.append("_method", "put");
      product.append("name", this.name);
      if(this.brandId){
        product.append("brand_id", this.brandId);
      }
      if(this.categoriesArray){
        for (let [index, category] of this.categoriesArray.entries()) {
          if(category){
            product.append(`categories[${index}]`, category);
          }
        }
      }

      product.append("purchase_price", this.purchasePrice);
      product.append("purchase_price_currency", this.currency);
      product.append("region", this.country);
      product.append("weight", this.weight);
      product.append("price", this.price);

      if (this.oldPrice) {
        product.append("old_price", this.oldPrice);
      }

      product.append("status", Number(!this.isNotActive));
      product.append("description", this.description);
      if(this.vendorId){
        product.append("vendor_id", this.vendorId);
      }

      if(this.storefrontsArray){
        for (let [index, storefront] of this.storefrontsArray.entries()) {
          if(storefront){
            product.append(`storefronts[${index}][id]`, storefront);
            product.append(`storefronts[${index}][value]`, 1);
          }
        }
      }

      if(this.tagsArray){
        for (let [index, tag] of this.tagsArray.entries()) {
          if(tag){
            product.append(`tags[${index}]`, tag);
          }
        }
      }

      if(this.options.entries()){
        for (let [index, option] of this.options.entries()) {
          product.append(`options[${index}][size]`, option.size);
          product.append(`options[${index}][color_id]`, option.color_id);
          product.append(`options[${index}][quantity]`, option.quantity);
        }
      }

      if(this.images.entries()){
        for (let [index, photo] of this.images.entries()) {
          product.append(`photos[${index}]`, photo);
        }
      }

      product.append("expired_at", this.removalTime);

      if(this.seasonsArray.length){
        for (let [index, season_id] of this.seasonsArray.entries()) {
          if(season_id){
            product.append(`season_ids[${index}]`, season_id);
          }
        }
      }

      this.materials.forEach((material, index) => {
        product.append(`features[${index}][name]`, material.name);
        product.append(`features[${index}][value]`, material.value);
      });

      product.append("draft", draft);

      if (draft) {
        await this.updateProduct({
          id: +this.$route.params.id,
          product
        });
      }else{

        this.$store.commit("SET_SHOW_LOADER", true);
        let success = await this.updateProduct({
          id: +this.$route.params.id,
          product,
        });
        if (success) {
          this.$router.replace("/products");
        }
        this.$store.commit("SET_SHOW_LOADER", false);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  .md-subheading {
    margin-top: 24px;
  }
}

.options {
  align-items: center;
}

.materials {
  flex: 1;
}

.params {
  justify-content: center;

  .md-raised {
    min-width: 40px;
    max-width: 40px;

    &::v-deep .md-ripple {
      padding: 10px !important;
    }
  }
}

.btn-delete-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
}

.row,
.column {
  display: flex;
}

.row {
  justify-content: space-between;
}

.column {
  flex-direction: column;
}

.buttons-wrap {
  display: flex;
  justify-content: space-between;
}

.button-wrap {
  display: flex;
  justify-content: center;
}

::v-deep {
  .md-list-item-text {
    width: unset;
    height: unset;
    position: relative;
    flex-direction: row;
    align-items: center;
  }

  .md-textarea {
    padding: 0 !important;
  }
}

.uploader-wrapper {
  position: relative;
  width: fit-content;

  &::v-deep {
    .validation-error {
      bottom: unset;
      top: 50%;
      transform: translate(110%, -50%);

      & + div {
        border-radius: 5px;
        border: 1px solid red;
      }
    }
  }
}
</style>
